var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
          },
          on: { ok: _vm.ok, hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.allowSelect
                    ? [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.disableOk,
                              size: "sm",
                              variant: "success",
                            },
                            on: {
                              click: function ($event) {
                                return ok()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$i18n.t(
                            "MANAGE" === _vm.mode
                              ? "button.close"
                              : "button.cancel"
                          )
                        )
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _vm.alertMsg != null
            ? _c("AlertFeedback", {
                attrs: {
                  msg: _vm.alertMsg,
                  details: _vm.alertMsgDetails.list,
                  detailTitle: _vm.alertMsgDetails.title,
                  alertState: _vm.alertState,
                },
                on: { resetAlert: _vm.resetAlert },
              })
            : _vm._e(),
          _vm.allowManage
            ? _c(
                "div",
                { staticClass: "grid-toolbar border" },
                [
                  _vm.canAdd()
                    ? [
                        _c(
                          "span",
                          { attrs: { id: "BTN_ADD_" + _vm.id } },
                          [
                            _c(
                              "b-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.createFolder()
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  style: {
                                    color: "var(--grid-toolbar-button)",
                                  },
                                  attrs: { icon: ["far", "plus"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "BTN_ADD_" + _vm.id,
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("button.add")) + " ")]
                        ),
                      ]
                    : _vm._e(),
                  _vm.canView()
                    ? [
                        _c("b-popover", {
                          attrs: {
                            target: "BTN_EDIT_" + _vm.id,
                            placement: "top",
                            triggers: "hover",
                            content: _vm.$t("button.edit"),
                          },
                        }),
                        _c(
                          "b-btn",
                          {
                            attrs: {
                              disabled: _vm.disableEdit,
                              id: "BTN_EDIT_" + _vm.id,
                            },
                            on: { click: _vm.editFolder },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "pen-to-square"] },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.canDelete()
                    ? [
                        _c("b-popover", {
                          attrs: {
                            target: "BTN_DELETE_" + _vm.id,
                            placement: "top",
                            triggers: "hover",
                            content: _vm.$t("button.delete"),
                          },
                        }),
                        _c(
                          "b-btn",
                          {
                            attrs: {
                              disabled: _vm.disableDelete,
                              id: "BTN_DELETE_" + _vm.id,
                            },
                            on: { click: _vm.removeDataview },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "trash-can"] },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _c("ag-grid-vue", {
            staticClass: "ag-theme-balham selector-grid-height",
            staticStyle: { width: "100%" },
            attrs: {
              id: "task-grid",
              gridOptions: _vm.gridOptions,
              columnDefs: _vm.columnDefs,
              context: _vm.context,
              defaultColDef: _vm.defaultColDef,
              autoGroupColumnDef: _vm.autoGroupColumnDef,
              getRowId: function (params) {
                return params.data.uuId
              },
              overlayNoRowsTemplate: _vm.overlayNoRowsTemplate,
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
              rowData: _vm.rowData,
              sideBar: false,
              suppressDragLeaveHidesColumns: "",
              suppressCellFocus: "",
              suppressContextMenu: "",
              suppressMultiSort: "",
              treeData: "",
              getDataPath: _vm.getDataPath,
            },
            on: { "grid-ready": _vm.onGridReady },
          }),
        ],
        1
      ),
      _c("DataviewFolderModal", {
        attrs: {
          id: _vm.folderUuid,
          visibility: _vm.visibility,
          folderName: _vm.folderName,
          parentData: _vm.folderParentData,
          show: _vm.createFolderDataviewShow,
        },
        on: {
          "update:show": function ($event) {
            _vm.createFolderDataviewShow = $event
          },
          success: _vm.createFolderModalOk,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.title_delete"),
            "ok-title": _vm.$t("button.confirm"),
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.confirmDeleteDataviewOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.confirmDeleteDataviewOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteDataviewShow,
            callback: function ($$v) {
              _vm.confirmDeleteDataviewShow = $$v
            },
            expression: "confirmDeleteDataviewShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("dataview.confirmation.delete_folder")) + " "
            ),
          ]),
        ]
      ),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }