var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.$t("dataview.select_column_title"),
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
            "ok-title": _vm.$t("button.select"),
            scrollable: "",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          disabled: _vm.disableColumnOK(),
                          size: "sm",
                          variant: "success",
                        },
                        on: { click: _vm.ok },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.ok")))]
                    ),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: _vm.alertError ? "danger" : "success",
                dismissible: "",
                show: _vm.showError,
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: {
                  icon: _vm.alertError
                    ? ["fas", "triangle-exclamation"]
                    : ["far", "check"],
                },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c("label", [_vm._v(_vm._s(_vm.$t("dataview.field.field")))]),
          _c(
            "div",
            { staticClass: "field-select-container" },
            _vm._l(_vm.fieldlist, function (field, index) {
              return _c("div", { key: index, staticClass: "d-inline-block" }, [
                _c("div", { staticClass: "column-list" }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-inline-block",
                      class: _vm.getEditFieldClass(index),
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.editField(index)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "field-label d-inline-block" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              field !== null && field !== ""
                                ? field.replace(/\(A\)|\(A,B\)|\(A,B,C\)/, "()")
                                : _vm.$t("dataview.placeholder.field")
                            ) +
                            " "
                        ),
                      ]),
                      _vm.showEditFieldIcon(index)
                        ? _c(
                            "div",
                            {
                              staticClass: "d-inline-block ml-2 mr-2",
                              attrs: { id: `EDIT_FIELD_${_vm.id}_${index}` },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "edit-icon-color",
                                attrs: { icon: ["far", "pen-to-square"] },
                              }),
                              _c("b-popover", {
                                attrs: {
                                  target: `EDIT_FIELD_${_vm.id}_${index}`,
                                  placement: "top",
                                  triggers: "hover",
                                  content: _vm.$t("button.edit"),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
                index !== _vm.fieldlist.length - 1
                  ? _c("div", { staticClass: "field-dot" }, [_vm._v(".")])
                  : _vm._e(),
              ])
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "mt-2 field-modal mb-3" },
            [
              _vm.editIndex !== -1
                ? _c(
                    "b-tabs",
                    {
                      attrs: {
                        "active-nav-item-class": "active",
                        "content-class": "mt-3",
                        pills: "",
                      },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _c(
                        "b-tab",
                        {
                          staticClass: "content-tab",
                          attrs: { title: _vm.$t("dataview.field.fields") },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  size: "md",
                                  placeholder: _vm.$t("dataview.search"),
                                },
                                model: {
                                  value: _vm.fieldfilter,
                                  callback: function ($$v) {
                                    _vm.fieldfilter = $$v
                                  },
                                  expression: "fieldfilter",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "magnifying-glass"],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.showEntities
                            ? _c("b-form-checkbox-group", {
                                staticClass: "mt-2 child-entity row-coloring",
                                attrs: {
                                  options: _vm.getChildren,
                                  stacked: "",
                                },
                                on: { change: _vm.changeChild },
                                model: {
                                  value: _vm.selectedChild,
                                  callback: function ($$v) {
                                    _vm.selectedChild = $$v
                                  },
                                  expression: "selectedChild",
                                },
                              })
                            : _vm._e(),
                          _vm.properties
                            ? _c("b-form-checkbox-group", {
                                class:
                                  _vm.getChildren.length % 2 === 0
                                    ? "row-coloring"
                                    : "row-coloring-alt",
                                attrs: { options: _vm.getFields, stacked: "" },
                                on: { change: _vm.changeField },
                                model: {
                                  value: _vm.selectedField,
                                  callback: function ($$v) {
                                    _vm.selectedField = $$v
                                  },
                                  expression: "selectedField",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        {
                          staticClass: "content-tab",
                          attrs: { title: _vm.$t("dataview.recent") },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  size: "md",
                                  placeholder: _vm.$t("dataview.search"),
                                },
                                model: {
                                  value: _vm.fieldfilter,
                                  callback: function ($$v) {
                                    _vm.fieldfilter = $$v
                                  },
                                  expression: "fieldfilter",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "magnifying-glass"],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("b-form-checkbox-group", {
                            staticClass: "mt-2",
                            attrs: { options: _vm.getRecent, stacked: "" },
                            on: { change: _vm.changeRecent },
                            model: {
                              value: _vm.selectedField,
                              callback: function ($$v) {
                                _vm.selectedField = $$v
                              },
                              expression: "selectedField",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _vm.useAgFunc && _vm.supportsFunctions()
                        ? _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mt-3 mb-2",
                              on: { input: _vm.updateName },
                              model: {
                                value: _vm.applyFunction,
                                callback: function ($$v) {
                                  _vm.applyFunction = $$v
                                },
                                expression: "applyFunction",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("dataview.applyfunction")))]
                          )
                        : _vm._e(),
                      _vm.useAgFunc && _vm.supportsFunctions()
                        ? [
                            _c("multiselect", {
                              staticClass:
                                "custom-dropdown-options enable-option-icon",
                              attrs: {
                                "max-height": 300,
                                options: _vm.agFunctions().map((i) => i.value),
                                "custom-label": _vm.getAgFunctionOptionLabel,
                                placeholder: "",
                                searchable: false,
                                "allow-empty": false,
                                showLabels: false,
                                disabled: !_vm.applyFunction,
                              },
                              on: { input: _vm.updateName },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "option",
                                    fn: function (props) {
                                      return [
                                        _vm.agFunction == props.option
                                          ? _c("font-awesome-icon", {
                                              staticClass:
                                                "selected-option-icon",
                                              attrs: { icon: ["far", "check"] },
                                            })
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          { staticClass: "option__title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getAgFunctionOptionLabel(
                                                  props.option
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3023671699
                              ),
                              model: {
                                value: _vm.agFunction,
                                callback: function ($$v) {
                                  _vm.agFunction = $$v
                                },
                                expression: "agFunction",
                              },
                            }),
                          ]
                        : _vm._e(),
                      _vm.useName
                        ? _c(
                            "b-form-group",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                label: _vm.$t("dataview.displayname"),
                                "label-for": "name",
                              },
                            },
                            [
                              _vm.useName
                                ? _c("b-form-input", {
                                    attrs: {
                                      size: "md",
                                      name: "name",
                                      placeholder: _vm.$t(
                                        "dataview.field.name"
                                      ),
                                    },
                                    model: {
                                      value: _vm.name,
                                      callback: function ($$v) {
                                        _vm.name = $$v
                                      },
                                      expression: "name",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
              _vm.useOrder
                ? _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _c("label", { staticClass: "d-block" }, [
                        _vm._v(_vm._s(_vm.$t(`dataview.field.direction`))),
                      ]),
                      _c("multiselect", {
                        staticClass:
                          "custom-dropdown-options enable-option-icon",
                        attrs: {
                          "max-height": 300,
                          options: _vm.sortDirectionOptions.map((i) => i.value),
                          "custom-label": _vm.getSortDirectionOptionLabel,
                          placeholder: "",
                          searchable: false,
                          "allow-empty": false,
                          showLabels: false,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "option",
                              fn: function (props) {
                                return [
                                  _vm.sortdirection == props.option
                                    ? _c("font-awesome-icon", {
                                        staticClass: "selected-option-icon",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                  _c("span", { staticClass: "option__title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getSortDirectionOptionLabel(
                                          props.option
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3516571411
                        ),
                        model: {
                          value: _vm.sortdirection,
                          callback: function ($$v) {
                            _vm.sortdirection = $$v
                          },
                          expression: "sortdirection",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }